import './particles.scss'

const Particles = () => {
    return (
            <div className="container__bg"><img className="background" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/221808/sky.jpg" alt="stars" />
                <h5 className="message">All of your dreams can come true<br />If you have the courage to pursue them</h5>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
                <div className="circle-container">
                    <div className="circle" />
                </div>
            </div>
  )
}

export default Particles 
